<script lang="ts" setup>
import Decentraland from '/assets/images/home/supported/decentraland.svg'
import ArtBat from '/images/home/supported/artbat.webp'
import HubCulture from '/images/home/supported/hubculture.webp'
import MayanWarrior from '/images/home/supported/mw.webp'
import Reface from '/images/home/supported/reface.webp'
import Bitfury from '/images/home/supported/bitfury.webp'
import Bridge from '/images/home/supported/bridge.webp'
import GoX from '/images/home/supported/go-x.webp'
import MarkStroke from '/images/home/supported/mark-stroke.webp'
import Eternalaw from '/images/home/supported/eternalaw.webp'

const { t } = useI18n()

const supported = [
  {
    id: 0,
    title: 'Decentraland',
    image: Decentraland,
  },
  {
    id: 1,
    title: 'ArtBat',
    image: ArtBat,
  },
  {
    id: 2,
    title: 'Hub Culture',
    image: HubCulture,
  },
  {
    id: 3,
    title: 'Mayan Warrior',
    image: MayanWarrior,
  },
  {
    id: 4,
    title: 'Reface',
    image: Reface,
  },
  {
    id: 5,
    title: 'Bitfury',
    image: Bitfury,
  },
  {
    id: 6,
    title: 'Bridge',
    image: Bridge,
  },
  {
    id: 7,
    title: 'Go X',
    image: GoX,
  },
  {
    id: 8,
    title: 'MarkStroke',
    image: MarkStroke,
  },
  {
    id: 9,
    title: 'Eternalaw',
    image: Eternalaw,
  },
  {
    id: 10,
    title: 'CASCADE.VC',
  },
  {
    id: 11,
    title: 'SingularityDao',
    image: '/images/home/supported/singularitydao.png',
  },
]
</script>

<template>
  <div>
    <p class="text-center text-2xl md:text-[2rem] px-5 lg:px-0">
      {{ t('funded-by-the-creators-of-brands-you-know-and-love') }}
    </p>
    <Swiper
      :loop="true"
      :modules="[SwiperAutoplay, SwiperMousewheel]"
      :space-between="14"
      :slides-per-view="'auto'"
      :autoplay="{
        delay: 2500,
        disableOnInteraction: false,
      }"
      :mousewheel="true"
      class="mt-15"
    >
      <SwiperSlide
        v-for="item in supported"
        class="slide max-w-68.5 cursor-pointer"
      >
        <div class="flex flex-row h-24 items-center justify-center">
          <NuxtImg
            v-if="item.title !== 'CASCADE.VC'"
            loading="lazy"
            :src="item.image"
            :alt="item.title"
            :class="{
              'object-cover w-9 h-9': item.title === 'Decentraland',
              'w-56 h-4.75': item.title === 'ArtBat',
              'w-63.5 h-21.5': item.title === 'Hub Culture',
              'w-37.5 h-22.75': item.title === 'Mayan Warrior',
              'w-43 h-16': item.title === 'Reface',
              'w-47 h-9.25': item.title === 'Bitfury',
              'w-32 h-17.25': item.title === 'Bridge',
              'w-35 h-15.25': item.title === 'Go X',
              'w-10 h-12': item.title === 'MarkStroke',
              'w-54.5 h-11.75': item.title === 'Eternalaw',
              'w-49.5 h-18': item.title === 'SingularityDao',
            }"
          />
          <div
            v-if="item.title === 'Decentraland'"
            class="font-500 text-[22px] text-white tracking-wider ml-2"
          >
            Decentraland
          </div>

          <div
            v-if="item.title === 'CASCADE.VC'"
            class="font-500 text-[22px] text-white tracking-wider"
          >
            CASCADE.VC
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
  </div>
</template>
